#drawer {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    -webkit-text-size-adjust: none;
}

#drawer-toggle {
    position: absolute;
    opacity: 0;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
    #drawer {
        position: fixed;
        top: 0;
        right: -100%;
        height: 100%;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
        z-index: 2;
        overflow: scroll;
    }

    #page-content {
        width: 100%;
        height: calc(100% - 50px);
        -webkit-overflow-scrolling: touch;
    }

    #drawer-toggle:checked ~ #drawer {
        right: 0px;
    }

    #drawer-toggle:checked ~ #page-content {
        width: calc(100% - 50vw);
    }
}
/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    #drawer {
        position: fixed;
        display: grid;
        grid-template-rows: 4% 96%;
        top: 0;
        right: -50vw;
        height: 100%;
        width: 50vw;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
    }

    #page-content {
        width: 100%;
        display: grid;
        grid-template-rows: 10% auto;
        grid-row-gap: 20px;
        height: 100vh;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    #drawer-toggle:checked ~ #drawer {
        right: 0px;
    }

    #drawer-toggle:checked ~ #page-content {
        width: calc(100% - 50vw);
    }
}

@media all and (max-width:350px) {

    #drawer-toggle:checked ~ #drawer {
        width: calc(100% - 50px);
        padding: 20px;
    }

    #drawer-toggle:checked ~ #page-content {
        margin-right: calc(100% - 50px);
    }
}
