.submit-form {
    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;

}

.form-field-title {
    font-size: 14px;
    color: var(--oc-gray-7);
    font-weight: 400;
}
