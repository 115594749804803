body,
html,
#root {
    height: 100%;
    padding: 0;
    margin: 0;
    background: var(--oc-gray-0);
}

#console {
    background-color: var(--oc-gray-9);
    overflow-y: scroll;
}

.columns {
    height: 100%;
    margin: 0;
}

.notification-custom {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;
    z-index: 101;
}

.line-scale div {
    background-color: #1b3344 !important;
}

.notification-custom {
    background-color: #ffb101;
}

.notification-custom-description {
    color: #1b3344;
    margin-bottom: 10px;
}

.acs-loader {
    text-align: center;
}

.bank-page-title {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 400;
}

.bank-page-sub-title {
    text-align: center;
    font-size: 14px;
}


/* Extra Small Devices, Phones */

@media only screen and (max-width: 480px) {
    .data-entry-container {
        display: grid;
        grid-template-areas: "cart" "checkout";
        height: 100vh;
    }

    .title {
        font-size: 18px;
        color: var(--oc-gray-7);
    }

    .page-layout {
        display: grid;
        grid-template-columns: 100vw;
    }

    .challenge-container {
        box-shadow: 0 6px 21px var(--oc-gray-3);
        border-radius: 4px;
        width: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        height: 85vh;
    }

    .powered-by {
        display: none;
    }

    #log-container {
        height: max-content;
    }
}


/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {
    body,
    html,
    #root {
        overflow-y: hidden;
    }

    .data-entry-container {
        display: grid;
        grid-template-areas: "checkout cart";
        grid-template-columns: 50% 50%;
    }

    .title {
        font-size: 20px;
        color: var(--oc-gray-7);
        font-weight: 400;
    }

    .page-layout {
        display: grid;
        grid-template-columns: auto minmax(0, 50%);
        height: 100vh;
    }

    .challenge-container {
        box-shadow: 0 6px 21px var(--oc-gray-3);
        border-radius: 4px;
        width: 600px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
        height: 70vh;
    }

    .powered-by {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: start;
        font-weight: 600;
        font-size: 12px;
        color: #7b8180;
        grid-gap: 5px;
        margin-right: 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
    }
}

.console-open-btn-hide {
    position: fixed;
    right: 0;
    top: 10px;
    margin-right: 10px;
    z-index: 0;
}

.console-open-btn {
    position: fixed;
    right: 0;
    top: 10px;
    margin-right: 10px;
    z-index: 1;
}

.hints-btn {
    position: fixed;
    left: 0;
    top: 10px;
    margin-left: 10px;
    font-weight: 500;
    z-index: 99;
}

.hints {
    background-color: var(--oc-gray-9) !important;
    z-index: 100 !important;
}

.hints-container {
    border-top: 1px solid var(--oc-gray-7);
    background-color: var(--oc-gray-9);
    padding-left: 5px;
}

.hide-hints {
    display: none;
}

.hint-list {
    margin-top: 10px;
}

.hint-list>li {
    margin-bottom: 5px;
    color: var(--oc-gray-6);
}

.hint-list>li>span {
    font-weight: bold;
}

.quick-view-title {
    color: var(--oc-grey-6);
    font-weight: 500;
}

.challenge-modal {
    background-color: var(--oc-white);
    overflow-y: hidden;
}

.logo {
    width: 70px;
}


/* Hosted Field Tweaks */
.forter-hosted-fields-focus{
    border-color: #3273dc;
    box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
    &:hover{
        border-color: #3273dc;
    }
}
