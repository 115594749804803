.complete-title {
    margin-top: 10px;
    font-size: 50px;
}

.complete-container {
    text-align: center;
}

.back-to-shop-button {
    margin-right: 5px;
}
