#order-table {
}

#order-table h2 {
    margin: 0;
}

.delete-btn {
    text-align: end;
    margin-right: 15px;
}

.total {
    font-size: 30px;
    margin-left: 10px;
}

.desc-section {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin-bottom: 20px;
}

.item-label {
    font-size: 14px;
    color: var(--oc-gray-6);
}

.pic-and-title {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: flex-start;
    align-items: center;
}

.prod-price {
    justify-self: right;
}
/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
    .product {
        width: 50px;
    }

    .order {
        box-shadow: 1px 1px 5px 1px var(--oc-gray-2);
        display: grid;
        align-items: center;
        background: var(--oc-white);
        grid-template-columns: auto auto;
        grid-gap: 10px;
        padding: 10px;
    }

    .calc-description {
        border-bottom: 1px solid var(--oc-gray-3);
        margin-top: 20px;
        padding: 10px;
    }

    .total-calc {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        margin-top: 10px;
        align-items: baseline;
        padding: 10px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 481px) {

    .product {
        width: 70px;
        padding: 10px;
    }

    .order {
        display: grid;
        align-items: center;
        grid-template-columns: auto auto;
        border-bottom: 1px solid var(--oc-gray-3);
    }

    .calc-description {
        border-bottom: 1px solid var(--oc-gray-3);
        margin-top: 20px;
    }

    .total-calc {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        margin-top: 10px;
        align-items: baseline;
    }
}
