@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono");

.drower-container {
  background: #242424;
}

.log-container {
  display: grid;
  grid-template-rows: auto 40px 1fr;
  height: 100%;
}

.log-container.compress {
  grid-template-rows: 40px 1fr;
}

.flow-container {
  padding: 10px;
  border-bottom: 1px solid #2e2d2d;
}

.console-container > .log-message {
  word-break: break-all;
  margin-bottom: 5px;
  white-space: pre-wrap;
}

.toolbar {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  height: fit-content;
  background-color: #333333;
}

.toolbar-title {
  color: grey;
}

.toolbar-buttons {
  display: grid;
  align-items: center;
  grid-template-columns: auto;
  grid-gap: 10px;
  margin-right: 15px;
}

.console-btn {
  color: grey;
}

.console-btn :hover {
  color: lightgray;
  cursor: pointer;
}

.issuer-connection-box {
  position: absolute;
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .console-container {
    font-family: "IBM Plex Mono", monospace;
    font-weight: 600;
    font-size: 12px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 10px;
    margin-top: 10px;
  }

  .quickview.is-active {
    width: 50% !important;
  }

  .flow-selector {
    display: grid;
    grid-template-columns: repeat(auto-fill,300px);
    grid-gap: 4px;
  }

  .log-header {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    font-size: 16px;
    padding-top: 5px;
    background-color: #333333;
    color: var(--oc-white);
  }

  .log-buttons {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    justify-content: end;
    grid-gap: 10px;
  }

  .log-title {
    margin-left: 10px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  .console-container {
    font-family: "IBM Plex Mono", monospace;
    height: max-content;
    font-weight: 600;
    font-size: 12px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 10px;
    margin-top: 10px;
  }

  .flow-selector {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 5px;
  }

  .log-header {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    font-size: 16px;
    padding-top: 5px;
    background-color: #333333;
    color: var(--oc-white);
  }

  .log-buttons {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: auto auto auto auto;
  }

  .log-title {
    display: none;
  }
}

.browser-console {
  color: var(--oc-orange-4);
}

.merchant-console {
  color: var(--oc-grape-4);
}

.error-console {
  color: var(--oc-red-5);
}

.default-console {
  color: var(--oc-white);
}

.payload-console {
  color: var(--oc-green-5);
}

.forter-console {
  color: var(--oc-cyan-5);
}

.console-quick-view {
  border-left: 1px solid var(--oc-gray-7);
  background-color: #21262a;
  width: 100%;
}

.custom-tabs {
  margin: 0 !important;
  font-size: .85rem;
}

.tab-text {
  margin-left: 5px;
}

.tabs.is-toggle a {
  border-width: 0;
}

.tabs.is-toggle li a {
  color: white;
}

.tabs.is-toggle li a:hover {
  color: white;
  background-color: var(--oc-gray-9);
}

.tabs.is-toggle li:last-child a {
  border-radius: 0;
}

.tabs.is-toggle li:first-child a {
  border-radius: 0;
}

.tabs.is-toggle li.is-active a {
  background-color: var(--oc-gray-9);
}

.tabs.is-toggle li.is-active a:hover {
  color: white;
}

.three-ds-settings-section {
  display: grid;
  grid-template-columns: 30% 70%;
  padding: 20px;
  border-bottom: 1px solid #2e2d2d;
  font-size: .85rem;
}

.three-ds-settings-object-section {
  display:flex;
  align-items: center;
}

.three-ds-settings-object-section + .three-ds-settings-object-section{
  margin-top: 30px;
}

.three-ds-settings-object-section  * + * {
  margin-left: 10px;
}
.three-ds-settings-object-section  input {
  height: 1.5em;
}

.three-ds-settings-section, .three-ds-settings-section-header{
  color: #b1b1b1;
}

.three-ds-settings-section-header{
  padding: 10px;
  font-weight: bold;
}

.hints-section {
  padding: 20px;
  color: #b1b1b1;
  border-bottom: 1px solid #2e2d2d;
  font-size: .85rem;
}

.hints-section > span {
  font-weight: bold;
}

.custom-radio {
  display: flex;
  align-items: center;
  font-size: .85rem;
  margin-left: 0 !important;
}
.custom-radio input {
  margin-right: 5px;
}

.options-menu {
  display: grid;
  grid-template-rows: auto auto auto;
}

.options-button {
  padding-left: 10px;
  padding-right: 10px;
}

.dropdown-item {
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
  padding-right: 10px !important;
  text-align: right !important;
}

.flow-selector-title {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.flow-button {
  color: black;
  font-size: 12px !important;
  font-weight: bold;
  border: 0;
}

.flow-button.is-active {
  background-color: var(--oc-blue-8);
  border-color: var(--oc-blue-8);
  color: var(--oc-white);
}

.approve-flow {
  background-color: #37b886;
}

.decline-flow {
  background-color: #f65252;
}

.verification-req-flow {
  background-color: #f9b005;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #242424;
}

::-webkit-scrollbar-thumb {
  background: var(--oc-gray-3);
  border-radius: 10px;
}

.products-select {
  width: 150px;
}

.schema-select {
  width: 70px;
}

.currencies-select {
  width: 150px;
}

.currencies-select select {
  width: 100%;
}

.settings-input {
  width: 300px;
}

.flow-util-button-icon {
  margin-right: 10px;
}

.util-button {
  background-color: #565552;
  border-color: transparent;
  color: whitesmoke;
}

.util-button:focus {
  background-color: #2f2f2f;
  border-color: transparent;
  color: whitesmoke;
}

.util-button:hover {
  background-color: #2f2f2f;
  border-color: transparent;
  color: whitesmoke;
}
