.top-bar {
    text-align: center;
}

.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    color: grey;
}

.back-button :hover {
    color: lightgray;
    cursor: pointer;
}
