.demo-flow {
    padding: 20px;
    background-color: var(--oc-gray-9);
    border-bottom: 1px solid var(--oc-gray-2);
    color: var(--oc-white);
}

.three-ds-toggle {
    align-self: center;
}

.email {
    grid-area: email;
}

.first-name {
    grid-area: first-name;
}

.last-name {
    grid-area: last-name;
}

.address {
    grid-area: address;
}

.zip-code {
    grid-area: zip-code;
}

.checkout-button {
    grid-area: checkout-button;
}

.expiry {
    grid-area: expiry;
}

.cvv {
    grid-area: cvv;
}

.expiry_cvv {
    grid-area: expiry_cvv;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
}

.secure-lock {
    color: var(--oc-green-7);
    font-weight: 300;
}

.shipping-info-title {
    grid-area: shipping-info-title;
}

.payment-info-title {
    grid-area: payment-info-title;
}

.settingRadioGroup {
    display: grid;
    grid-template-rows: auto auto auto;
    row-gap: 5px;
}

.cart {
    grid-area: cart;
}

.checkout {
    grid-area: checkout;
    padding: 10px;
}

.powered-by {
    grid-area: powered-by;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
    .checkout {
    }

    .brand {
        font-size: 20px;
        font-weight: 500;
    }

    .input-container {
        display: grid;
        grid-row: auto auto auto;
        row-gap: 10px;
    }

    .secure-lock {
        font-size: 14px;
        text-align: center;
    }

    .cc_image {
        height: 20px;
    }

    .cart {
        padding: 10px;
    }

    .error-message {
        margin-top: 10px;
        width: auto;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 481px) {

    .checkout {
        background-color: white;
        box-shadow: 1px 1px 5px 1px var(--oc-gray-2);
    }

    .brand {
        font-size: 30px;
        font-weight: 500;
    }

    .input-container {
        display: grid;
        grid-column-gap: 10px;
        grid-template-rows: auto auto;
    }

    .shipping-info {
        display: grid;
        grid-column-gap: 10px;
        grid-template-areas: "shipping-info-title shipping-info-title"
        "first-name last-name"
        "email email"
        "address address"
        "zip-code zip-code";
        padding: 10px;
    }

    .payment-info {
        padding: 10px;
    }

    .cc_image {
        height: 24px;
    }

    .cart {
        margin: 50px 30px 0px 30px;
    }

    .error-message {
        margin-top: 10px;
        width: auto;
        background-color: var(--oc-red-8);
        color: var(--oc-white);
    }

    .powered-by {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: start;
        font-weight: 600;
        font-size: 12px;
        color: #7b8180;
        grid-gap: 5px;
        margin-left: 5px;
        margin-top: 10px;
    }
}

.select.is-grey:not(:hover)::after {
    border-color: var(--oc-gray-6);
}

.three-ds-title {
    color: var(--oc-white-2);
}

.settingRadioGroup > label:hover {
    color: var(--oc-gray-5);
}

.checkbox {
    display: flex;
    align-items: center;
}

.checkbox input {
    margin-right: 5px;
}

.checkbox:hover {
    color: var(--oc-gray-5);
}
